<template>
  <div class="card">
    <div class="card-header header-elements-inline" >
      <h5 class="card-title">Category Creation</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload" ></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <form action="#">

        <div class="form-group row">
          <label class="col-lg-2 col-form-label">Name:</label>
          <div class="col-lg-10">
            <input type="text" class="form-control" placeholder="Enter Category Name here..." maxlength="100">
          </div>
        </div>

        <div class="form-group row">
          <label class="col-lg-2 col-form-label">Print Name:</label>
          <div class="col-lg-10">
            <input type="text" class="form-control" placeholder="Enter your print name here..." maxlength="100">
          </div>
        </div>

      </form>
    </div>

    <div class="card-footer">
      <div class="text-right" >
        <button type="button" class="btn btn-primary" >Save<i class="icon-paperplane ml-2"></i></button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'CategoryForm',
  data () {
    return {
      category: {}
    }
  },
  component: {

  },
  created () {
    this.$data.category = JSON.parse('{"id": 0, "code": "", "name": "", "printName": ""}')
  },
  mounted () {

  },
  methods: {
    closeWindow(){
      this.$parent.$parent.closeModal();
    }
  }
}
</script>

<style scoped>

</style>
